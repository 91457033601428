form{
    width: 100%;
    margin: 20px auto;
    div{
        width:100%;
    }

    input:not([type=checkbox]),input:not([type=color]),textarea,select{
        width: 100%;
        padding: 10px;
        font-size: 16px;
        margin: 5px 0px;
        background-color: white;
        border: 1px solid #C1C1C1;
        border-radius: 4px;

        &[type=file]{
            background-color: transparent;
            border: 0px;
        }
        font-family: 'Montserrat';
        font-style: italic;
    }

    input.choix-img {
        padding-left: 0px;
    }
    select{
        height: 50px;
    }
    
    input[type=color]{
        width: 50px;
        height: 50px;
        padding: 2px;
        cursor: pointer;
        
    }

    button.terminer{
        margin-left:10px !important;
        background-color: $background-color;
        color:black;
        font-weight: 500;
    }

    &.ajout-task{

        .content-form-ajout-task {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin: 0px 0px 10px 0px !important;
        }
        
        input{
            width: 95%;
            padding: 0px 10px;
            margin: 5px;
            height: 52px;
            
        }

        .input-number {
          width: 95%;
          padding: 0px 10px;
          margin: 5px !important;
        }

        select{
            height: 52px;
            width: 95%;
            padding: 0px 10px;
            margin: 5px 0px;
        }

        div.made-by{
            position: relative;
            input{
                padding: 15px 10px !important;
            }
            input.has-suggestions{
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                margin-bottom: 0px;
                
                &:focus{
                    outline: none;
                }
            }
            .suggestions{
                width: 95%;
                background-color: white;
                margin: 5px;
                margin-top: -10px;
                padding-top: 5px;
                position: absolute;
                z-index: 10;

                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                border: 1px solid #C1C1C1;
                border-top: 0px;

                div.suggestion{
                    padding: 15px 10px;
                    cursor: pointer;
                    &:hover{
                        background-color: white;
                    }
                }
            }
        }
    }

    select{
        padding: 10px;
        background-color: white;
        border: 1px solid #C1C1C1;
        border-radius: 4px;
        font-size: 16px;
        margin: 5px 0px;
    }
}

button{
    background-color: $primary-color;
    color:white;
    padding: 15px 20px;
    margin:10px 0px;
    border:0px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    transition-duration: 1s;
    opacity: 1;
    cursor: pointer;
    &:hover{
        opacity: 0.7;
    }
}

.input-number{
    width: 100%;
    padding: 2px 30px;
    font-size: 16px;
    margin: 5px 0px;
    background-color: white;
    border: 1px solid #C1C1C1;
    border-radius: 4px;
    font-family: 'Montserrat';

    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 10px !important;

    p{
        margin: 0px 20px;
        width: 80%;
    }
    svg{
        width: 20px;
        height: 20px;
        margin: 5px;
        cursor: pointer;
        opacity: 0.7;
        &.active{
            opacity: 1;
        }
    }

    input{
        margin: 0 !important;
        border:0px !important;
        width: 70px !important;
        &:focus{
            outline: none;
        }
    }

    .select-mode{
        width: fit-content;
        display: flex;
        align-items: center; 
        justify-self: flex-end;
    }
}

form.modificationTitre{
    width: 250px;
    margin: 0px 30px 0px 0px;
    padding: 0px;
    input{
        width: inherit;
        font-size: 30px;
        margin: 0px 12px;
        color:black;
        background-color: transparent;
        border: 0px solid;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        padding: 0px;
        &:focus{
            outline: none;
        }
    }
}

form.modificationNomTicket{
    width: auto;
    margin:0px;
    padding: 0px;
    input{
        width: inherit;
        font-size: 35px;
        margin: 0px 0px;
        color:black;
        background-color: transparent;
        border: 0px solid;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        padding: 0px;
        &:focus{
            outline: none;
        }
    }
}

form.modificationDescriptionTicket{

    width: 100%;
    margin:0px;
    padding: 0px;

    textarea{
        width: inherit;
        font-size: 16px;
        margin: 0px 0px;
        color:black;
        border:0px solid;
        background-color: transparent;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        padding: 0px;
        &:focus{
            outline: none;
        }
    }
    
}

