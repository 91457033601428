.connexion{
    width: 100vw;
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;

    .container{
        width: 95vw;
        max-width: 400px;
        text-align: center;

        img{
            margin-bottom: 20px;
        }

        form input{
            margin-bottom: 20px;
            border-radius: 10px;
            border:0px;
            padding: 20px;
            &::placeholder{
                color: grey;
            }
        }
    }
    
    .error{
        margin-top: 10px;
        color:red;
    }
    .success{
        color:green;
    }

    form p{
        margin: 10px;
        strong{
            font-weight: 700 !important;
        }
    }
}