.tasks{

    margin:20px 0px;

    .task{
        display: flex;
        height: 50px;
        align-items: center;
        border-radius: 4px;
        &:nth-child(2n+1){
            background-color: rgb(252, 252, 252);
        }
        &:hover div.control{
            opacity: 1;
        }
        
        .increments {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: $background-color;

            &:hover span{
                font-weight: bold;
            }

            span{
                cursor: pointer;
                font-size: 15px;
                background-color: $background-color;
              
            }
        }

        .bloc-task {
            width: 30%;
            text-align: center;
            cursor: pointer;
            
            &.control{
                width: 10%;
                opacity: 0;
                transition-duration: 0.2s;
                svg{
                    width: 20px;
                    height: 20px;
                }
            }

            &.bloc-task-increments {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            
        }
    }

}