

.container-projets{
    width: 100%;
    padding-top: 40px;
    h1{
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 30px;
    }

}

a{
    color: black;
}

.titre-section{
    display: flex;
    gap:20px;
    align-items: center;
    margin-bottom: 20px;

    h1{
        margin-bottom: 0px;
    }

    .button{
        padding:20px;
        color:white;
        background-color: $primary-color;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
    }

    .select-order {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 20px;
        p {
            margin-right: 20px;
        }
    }
}
.projets section{

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 100px;

    

    .projet{
        cursor:move !important;

      

        a{
            cursor: pointer;
        }
    }
    
    a{
        text-decoration: none;
        color:black;
        cursor: pointer;
    }


    .projet{
            width: calc(25% - 20px);
            
            //height: 150px;
            background-color: white;
            position: relative;
            border-radius: 6px;
            border: 1px solid $secondary-color;
            padding:15px;

            @media (max-width: 1280px){
                width: 300px;
            }

            @media (max-width: 768px){
                width: 100%;
            }

            &.archived{
                border: 1px solid grey;
                opacity: 0.6;
            }

            &.addProjet{
                background-color: white;
                cursor: pointer;

                img{
                    width: 50px;
                    margin-bottom: 20px;
                }
            }

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .content{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                flex-direction: column;
                img{
                    margin-bottom: 20px;
                    max-width: 150px;
                    height: 70px;
                    object-fit: contain;
                    object-position: center;
                }

                h2{
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                }
            }

        .projetfav{
            position: absolute;
            top:10px;
            right:10px;

            svg{
                width: 25px;
                height: 25px;
                cursor: pointer;
                opacity: 0.5;
            }
        }
     }
         

}

#section-all-projets {
    justify-content: center;
}

.reverse-order-projets {
    flex-direction: row-reverse !important;
    flex-wrap: wrap-reverse !important;
}