
.tickets .main{

    padding: 20px 0px; 

    .search{
        min-width: 400px;
        width: 30%;
        background-color: white;

        padding:2px 10px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        justify-content: flex-start;
        margin-bottom: 10px;

        input{
            width: 90%;
            background-color: transparent;
            height: 100%;
            font-family: 'Montserrat';
            padding: 10px 5px;

            border: 0px solid ;
            &:focus{
                outline: none;
            }

        }

        .loupe{
            width: 5%;
        }

        .croix{
            width: 5%;
            max-width:21px;
            height: 21px;
            svg{
                width:21px;
                height: 21px;
                cursor: pointer;
            }
        }
    }
    .container-infos-projet{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;

        .infos{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;

            @media screen and (max-width: 1200px) { 
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            button{
                transition-duration: 0.1s !important;
            }

            .infos-actions{
                display: flex;
                align-items: baseline;
                gap: 10px;
                margin: 10px;


                       
                .container-titre {
                    display: flex;
                    width: 100%; 
                    align-items: baseline;

 
                    svg{
                        width:20px;
                        height:20px;
                        cursor: pointer;
                    }
    
                    h1{
                        font-size: 30px;
                        margin-left: 12px;
                        margin-right: 12px;
                        margin-bottom: 20px;
                        cursor: pointer;
                        white-space: nowrap;
                    }
             
                }

                .container-add-more {
                    display: flex;
                }


                .button{
                    min-width: 40px;
                    height: 40px;
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    border-radius: 6px;

                    font-size: 14px;

                    cursor: pointer;

                    div{
                        display:flex;
                        svg{
                            margin-right: 5px;
                        }
                        .spinner-download {
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                        }
                        .spinner-download:after {
                            content: " ";
                            display: block;
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            border: 2.5px solid #3164A0;
                            border-color: #3164A0 transparent #3164A0 transparent;
                            animation: spinner-ring 1.2s linear infinite;
                          }
                          @keyframes spinner-ring{
                            0% {
                              transform: rotate(0deg);
                            }
                            100% {
                              transform: rotate(360deg);
                            }
                          }
                    }

                    svg{
                        width: 12.5px;
                        height: 12.5px;
                    }

                    &.add{
                        background-color: $primary-color;
                        color:white;
                        margin-right: 10px;
                    }
                    &.delete{
                        background-color: #E30613B2;
                        svg{
                            color:white;
                        }
                    }
                }
            }
            .periode{
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 10px;
                .mode-affichage{
                        display: flex;
                        gap:6px;

                        button{
                            padding: 10px 26px;
                            color:black;
                            background-color: white;
                            border-radius: 90px;
                            font-weight: 500;
                            margin: 0px 0px 10px 0px;
                            
                            &.active{
                                color: white;
                                background-color: $primary-color;
                            }

                        
                        }

                    }
                .select-periode{
                    margin: auto;
                    display: flex;
                    width: fit-content;
                    gap: 20px;
                    align-items: center;
                    margin-top: 10px;
                    svg{
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                    p{
                        min-width: 180px;
                        text-transform: capitalize;
                        font-weight: 600;

                    }

                    .selection-periode{
                        font-weight: 600;
                        border:0px;
                        &:focus{
                            border:0px;
                        }
                    }
                }
            }

            
        }

        .synthese-visuelle{
            width: 100%;
            display: flex;
            margin-bottom: 20px;
       

            .chiffres{

                display: flex;
                flex-wrap: wrap;
                gap:20px;
                width: 370px;
                max-width: 100%;
                margin-bottom: 20px;
                
                &.has-enveloppe .chiffre{
                    width: calc(50% - 20px);
                    margin-right: 0px;
                }

                &.has-enveloppe .chiffre p{
                    font-size: 14px;
                }

                &.has-enveloppe .chiffre span{
                    font-size: 20px;
                }


                .chiffre{
                   
                    width: calc(100% - 20px);
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    margin-right: 15px;
                    
                    
                    span{
                        display: block;
                        font-size: 24px;
                        font-weight: 600;
                    }
                    &.good{
                        width: calc(100% - 20px);
                        background-color:#369E37B2 ;
                        color:white;
                    }

                    &.warning{
                        width: calc(100% - 20px);
                        background-color:#c0392bB2 ;
                        color:white;
                    }

                }


            }


            .chart-container{
                max-width: 440px;
                width: 100%;
                background-color: white;
                border-radius:6px;
                padding: 20px;
                margin-left: 0px;
                margin-right: 20px;
                margin-bottom: 20px;

                h2{
                    font-weight: 600;
                    margin-bottom: 6px;
                    font-size:18px;
                }

            }
            .chart-month {
                margin-right: 0px;
            }


        }

        
        
    }

    

    .ticket-add{
        background-color: white;
        position: fixed;
        bottom:0px;
        right: 0px;
        padding: 15px 15px 15px 15px;
        text-align: center;
        border-top-left-radius: 10px;
        cursor: pointer;
        box-shadow: 3px 3px 10px 3px #ddd;

        img{
            width: 50px;
        }

        h2{
            font-size: 14px;
            display: none;
        }
    }

    div.tickets-container{
        width: 100%;
        background-color: rgba(255, 255, 255, 0.60);
        border-radius:6px;
        font-size: 12px;

        div.header-table{
            background-color: white;
            padding: 10px 20px;
            border-radius:6px;
            display: flex;
            align-items: center;
            font-weight: 600;


            .date{
                width: 10%;
                cursor: pointer;
                //vertical-align: middle;
                svg{
                    width: 15px;
                    height: 15px;
                    &.fa-sort-up{
                        margin-top: 6px;
                        margin-bottom: -6px;
                    }
                }
            }
        }

        .date{
            width: 10%;
        }

        .name-ticket{
            width: 20%;
        }
        .description{
            width: 30%;
            text-align: left;
            p{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 500px;
            }
        }

        .data{
            width: 10%;
            text-align: center;

        }

        div.tickets-body{

            .new_cost{
                width: 100%;
                padding:20px;
                font-weight: 600;
                text-align: center;
                font-size: 11px;
                background-color: $background-color;
            }
            

            .ticket{
                width: 100%;
                padding: 12.5px 10px;
                background-color: transparent;
                transition-duration: 1s;
                cursor: pointer;
                &:hover{
                    background-color: white;
                }
                display: flex;

                &.client{
                    cursor:default;
                }
               
                .date{
                    //text-align: center;
                }
            }

        }

    }
    table.tickets-container{
        width: 100%;
        tr.ticket{
            width: 100%;
            cursor: pointer;

            &.table-header{
                font-weight: 700;
                padding: 20px 10px;
                background-color: white;
                border-radius: 20px;
            }


            align-items: center;
            padding:5px 10px;
            font-size: 13px;
            background-color: whitesmoke;
            &:nth-child(2n){
                background-color: white;
            }

            td{
                padding:10px 3px; 
            }

            td
            td.name-ticket{
                width: 20%;
            }
            td.description{
                width: 20%;
                text-align: left;
                p{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 500px;
                }
            }

            td.data{
                width: 10%;
                text-align: center;

            }
        }

        .succes{
            color:green;
        }
    }

}