.modal{
    position: fixed;
    top:0;
    left: 0px;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);

    display: flex;
    align-items: center;
    justify-content: center;

    &.params{
        .modal-container{
            width: 1100px;

            .modal-header{
                img {
                    width: 100%;
                    height: auto;
                    max-width: 140px;
                }
                display: flex;
                justify-content: space-around;

            }
            .modal-content{
                padding:20px 50px;
            }

            .validation{
                display:flex;
                justify-content:flex-end;
            }

            .modules{
                display: flex;
                flex-wrap: wrap;
                gap:40px 15px;

                @media screen and (max-width: 993px) {
                    gap: 10px 15px;
                }

                h3{
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                .statut{

                }
                
                .commentaires {
                    margin-left: 40px;
                    width: 50%;
                    height: 90px;
                    margin-bottom: 80px;

                    h2 {
                        text-align: left;
                    }

                    .addCommentaire {
                        input[type=textarea] {
                            width: 100%;
                            padding: 12px 20px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            font-size: 16px;
                            resize: none;
                          }
                    }

                    @media screen and (max-width: 993px) {
                        margin-left: 0px;
                        width: 100%;
                    }

                }
                .informations,.statut{
                    width: calc(25% - 30px);

                        form{
                            margin: 0px;
                            margin-bottom: 10px;
                        }
                    p{
                        font-size: 12px;
                    }
                }

                .modif-logo{
                    width: calc(25% - 30px);
                    padding: 0px 20px;

                    img{
                        max-width: 100%;
                        max-height: 100px;
                        display: block;
                        margin: auto;
                    }
                    input{
                        margin-bottom: 10px;
                    }

                    span{
                        text-decoration: none;
                        font-weight: 600;
                        cursor: pointer;
                        margin-top: 10px;
                    }


                }

                .partage-client{
                    width: calc(25% - 30px);
                    
                    .info{
                        background-color: $background-color;
                        padding:10px;
                        border-radius: 6px;
                        margin: 5px 0px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width:fit-content;
                        gap: 20px;
                        flex-wrap: wrap;
                        a{
                           word-break:break-all; 
                           width: 200px;
                        }
                        svg{
                            width:15px;
                            height: 15px;
                            cursor: pointer;
                        }
                    }
                }

                

                .gestion-enveloppe{
                    width: calc(60% - 30px);
                    
                }

                .gestion-cout{
                    width: calc(40% - 30px);
                    &-66{
                        width: calc(66% - 30px);  
                    }
                }

                .gestion-cout, .gestion-enveloppe{
                    form{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap:10px;
                        &>div{
                           width: calc(33% - 10px);
                            
                        }
                        input:not([type=checkbox]){
                            width: 100%;
                            border-radius: 0px;
                        }

                        .expiration{
                            font-size: 10px;
                        }

                    }
                }
            }

        }


    }



    &.close{
        display: none;
    }

    .temps-passe{
        h3{
            margin-bottom: 10px;
            margin-top:10px;
            font-size: 20px;
            font-weight: bold;
        }
        .container-periode{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 30px 0px;
            svg{
                width: 15px;
                height: 15px;
                cursor: pointer;
            }
            .select-periode{
                display: flex;
                gap:20px;
                align-items: center;
            }
        }
        .ressources{
            margin-bottom: 30px;
            .ressource{
                padding: 5px 10px;
                margin: 10px;

                &.head{
                    background-color: $background-color;
                    font-weight: 600;
                    padding: 10px;
                    border-radius: 6px;
                }

                display: flex;
                justify-content: space-between;

               .collaborateur,.poste{
                   width: 40%;
               }
               .cout{
                   width: 20%;
                   text-align: right;
               }
               

                .name{
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .poste{
                    opacity: 0.8;
                    font-style: italic;
                }

                .temps{
                    margin-top: 40px;
                    font-size: 30px;
                    text-align:right;
                    font-weight: bold;
                }
            }

        }
    }

    .importTicket{
        p{
            margin: 10px 0px;
            font-weight: 600;
        }

        ul{
            list-style:disc;
            padding-left: 30px;
            li{
                margin: 0 10px;
            }
        }

        table{
            margin-top:10px;
            tr{
                margin: 10px 0px;
                border-spacing: 5px 1rem;
            }
        }
    }

    .modal-container{
        max-width: 90vw;
        width: 800px;
        max-height: 90vh;
        background-color: white;
        position: relative;
        border-radius: 6px;
        overflow: auto;

        .modal-header{
            width: 100%;
            background-color: $background-color;
            padding: 32px 50px ;

            .close{
                position: absolute;
                right:10px;
                top:10px;
                border:none;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
            }

            h1{
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .modal-header-ticket{
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: $background-color;
            padding: 32px 50px;

            .close{
                position: absolute;
                right:10px;
                top:10px;
                border:none;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
            }

            .title-delete {
                display: flex;

                h1{
                    font-weight: 600;
                }

                .button {
                    min-width: 40px;
                    height: 40px;
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    border-radius: 6px;
                    font-size: 14px;
                    cursor: pointer;
                    margin-left: 20px;

                    &.delete {
                        background-color: #E30613B2;
                        svg{
                            width: 12px;
                            color:white;
                        }
                    }
                }
            }

            .infos{
                display: flex;
                width: 100%;
                max-width: 580px;
                justify-content: space-between;
                margin: 27px 0px 0px 0px;
                font-size: 20px;
    
                span{
                    font-weight: bolder;
                    margin-right: 5px;
                }
            }

            .createdAt {
                margin-top: 10px;
            }

            .content-modif-description {
                margin-top: 10px;
                max-width: 580px;
            }

        }

        .modal-content{
            padding: 20px 60px;

            hr {
                height: 1px;
                background-color: rgb(236, 236, 236);
                border: none;
                margin-bottom: 0px;
            }

            .task-guide{
                display: flex;
                height: 50px;
                align-items: center;
                background-color: $background-color;
                border-radius: 4px;
                margin-top: 20px;
            
                div{
                    width: 30%;
                    text-align: center;  
                    
                    &.empty {
                        width: 10%;
                        height: 20px;
                    }
                }
            }
        }

        

        h1{
            font-size: 35px;
            font-weight: 700;
        }

        p.demandeur{
            opacity: 0.9;
            margin-bottom: 10px;
        }

        .titre-modal{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .controls{
                display: flex;
                
                svg{
                    width: 20px;
                    height: 20px;
                    opacity: 0.5;
                    transition-duration: 1s;
                    cursor: pointer;
                    margin: 0px 10px;

                    &:hover{
                        opacity: 1;
                    }
                }
             }

        }

        form div{
            margin: 10px 0px;

            button{
                text-align: center;
                margin: auto;
            }
        }

        form .submit-button {
            display: flex;
            justify-content: flex-end;
            button{
                margin-top: 10px;
                text-align: center;
                margin: inherit;
            }
        }

        .succes{
            margin: auto;
            p{
                text-align:center;
                color:green;
            }
        }
    }

    .content{
        width:100%;
        .preview{
            width:100%;
            overflow:scroll;
            height: 500px;
            border: 2px solid black;
        }  
    }

    .export-factu{
        display: flex;

        div{
            width: 50%;
           &:nth-child(1){
                padding-right: 10px;
           }
           &:nth-child(2){
                padding-left: 10px;
            }

            select{
                margin: 0;
            }
        }
    }

    h2{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    form.addCredit{
        display: flex;
        gap: 20px;

        .expiration{
            font-size: 14px;
            display: flex;
            align-items: center;
            margin: auto;

            &.full-height{
                height: 100%;
            }

            input{
                display: inline-block;
                width: fit-content;
                margin-right: 10px;
            }
        }
    }
    .enveloppes{
        &.budget-plancher {
            width: calc(66% - 30px);
        }
        .enveloppe{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            margin: 5px 0px;
            border-radius:6px;
            font-size:14px;

            &.head{
                background-color: $background-color;
                color:black;
                font-weight: 600;
                font-size: 14px;
                padding:15px 5px;
            }

            font-size: 12px;

            .date{
                width: 20%;
            }

            .description{
                width: 50%;
            }

            .credit{
                width: 20%;
            }


            .delete{
                width: 10%;
                cursor: pointer;
                svg{
                    color:black;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
   
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid black;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: black transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  