.main .budgets {
    padding: 20px 0px;

    .container-top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 60px;

        .titre-section {
            font-size: 30px;
            font-weight: 700;
        }

        .periode {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 10px;

            .mode-affichage {
                display: flex;
                gap: 6px;

                button {
                    padding: 10px 26px;
                    color: black;
                    background-color: white;
                    border-radius: 90px;
                    font-weight: 500;
                    margin: 0px 0px 10px 0px;

                    &.active {
                        color: white;
                        background-color: $primary-color;
                    }


                }

            }

            .select-periode {
                margin: auto;
                display: flex;
                width: fit-content;
                gap: 20px;
                align-items: center;
                margin-top: 10px;

                svg {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }

                p {
                    min-width: 180px;
                    text-transform: capitalize;
                    font-weight: 600;

                }

                .selection-periode {
                    font-weight: 600;
                    border: 0px;

                    &:focus {
                        border: 0px;
                    }
                }
            }
        }
    }

    .titre-tableau {
        font-size: 20px;
        font-weight: 700;
        margin: 30px 0px 10px;
    }

    // TABLEAU DES PROJETS 
    div.budgets-container {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.60);
        border-radius: 6px;
        font-size: 12px;
        // height: 350px;
        // overflow-y: auto;

        table {
            min-height: 140px;
            // overflow: hidden;
            border-radius: 6px;
            margin: 0;
            padding: 0;
            width: 100%;
            table-layout: fixed;
            background-color: rgba(255, 255, 255, 0.2);
            // border-collapse: collapse;

            tbody {
                position: relative;
            }

            thead {
                border-radius: 6px;
            }

            caption {
                font-size: 1.5em;
                margin: .5em 0 .75em;
            }

            tr {
                background-color: rgba(255, 255, 255, 0.1);
                transition-duration: 0.7s;
                padding: 1em .35em;
                height: 55px;
                border-radius: 6px;


                &:hover {
                    background-color: white;
                }
            }

            // th:not([scope=row]) {
            //     position: -webkit-sticky;
            //     position: sticky;
            //     top: 0;
            //     z-index: 2;
            //     align-self: flex-start;
            // }


            th {
                // position: sticky;
                // align-self: flex-start;
                // top: 0;
                // z-index: 2;
                border-radius: 6px;
                background-color: white;
                font-weight: 600;
                padding: 1em;
                text-align: center;
                vertical-align: middle;
                font-size: 1em;

                .editer {
                    width: 60px;
                }
            }

            th.editer {
                width: 80px;
            }

            td {
                padding: 1.7em .625em;
                text-align: center;
                vertical-align: middle;

                .link-projet {
                    width: 100%;

                    img {
                        width: 100%;
                        max-width: 75px;
                        height: auto;
                        margin-bottom: 7px;
                    }
                }

                .button {
                    cursor: pointer;

                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            .spinner-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: inline-block;
                text-align: center;
                padding: 30px 0px;


                .spinner-download {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                }

                .spinner-download:after {
                    content: " ";
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 2.5px solid #3164A0;
                    border-color: #3164A0 transparent #3164A0 transparent;
                    animation: spinner-ring 1.2s linear infinite;
                }

                @keyframes spinner-ring {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }


        @media screen and (max-width: 900px) {
            table {
                border: 0;

                .spinner-container {
                    position: absolute;
                    top: -40px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                thead {
                    border: none;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                tr {
                    display: block;
                    border-bottom: 1px solid #a7bed9;
                    height: auto;
                }

                td {
                    display: block;
                    font-size: 0.9em;
                    text-align: right;

                    .link-projet {
                        width: 100%;

                        img {
                            width: 100%;
                            max-width: 70px;
                            height: auto;
                        }
                    }
                }

                td::before {
                    content: attr(data-label);
                    float: left;
                    font-weight: bold;
                    text-align: center;
                    vertical-align: middle;
                }

                table td:last-child {
                    border-bottom: 0;
                }
            }


        }
    }
}