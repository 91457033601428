
html body{
    background-color:white;
    font-family: 'Montserrat',sans-serif;
    box-sizing: border-box;

    background-color: $background-color;

    .main{
        width:1280px;
        max-width: 95vw;
        margin: auto;
        margin-top: 70px;
    }

    .header{
        width: 100vw;
        height: 70px;
        position: fixed;
        z-index: 10;
        top:0px;
    }
    .header-container{
        background-color: white;
        width: 100vw;
        height: 100%;
        padding:20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.01);



        .logo-container{
            display: flex;
            align-items: center;
            gap:20px;
            .logo-r2{
                width: 50px;

                img{
                    width: 100%;
                }
            }
        }


        .search-container{

            position: relative;
            .search-results{
                width: 100%;
                background-color: white;
                height: 0px;
                border: 1px solid $background-color;
                border-top:0px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                position: absolute;
                display: none;

                max-height: 70vh;
                overflow: scroll;

                &:last-child{
                    border-bottom: none;
                }

                div:hover{
                    background-color: $background-color;
                    cursor: pointer;
                }
                transition-duration: 0.5s;

                a{
                    text-decoration: none;
                }

                .projet{
                    width: 100%;

                    display: flex;
                    align-items: center;
                    gap: 20px;
                    border-bottom: 1px solid $secondary-color;

                    &.archived{
                        border: black;
                    }

                    font-size: 12px;

                    padding:10px 20px;
                    h2{
                        font-weight: 600;
                        margin-bottom: 5px;
                        font-size: 16px;
                    }
                    img{
                        width: 70px;
                    }
                }
                .ticket{
                    width: 100%;
                    display: flex;
                    align-items: center;

                    padding:10px 20px;
                    border-bottom: 1px solid $secondary-color;
                    font-size: 12px;
                    h2{
                        font-weight: 600;
                        margin-bottom: 5px;
                        font-size: 16px;
                    }


                }
            }

            &.results-open .search-results{
                display: block;
                height: auto;
            }

            &.results-open .search{
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            
            .search{
            width: 600px;
            background-color: $background-color;

            padding:2px 10px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            justify-content: flex-start;

            input{
                width: 90%;
                background-color: transparent;
                height: 100%;
                font-family: 'Montserrat';
                padding: 10px 5px;

                border: 0px solid ;
                &:focus{
                    outline: none;
                }

            }

            .loupe{
                width: 5%;
            }

            .croix{
                width: 5%;
                max-width:21px;
                height: 21px;
                svg{
                    width:21px;
                    height: 21px;
                    cursor: pointer;
                }
            }

            }
        }

        .login{
            margin-right: 40px;
            img{
              width: 40px;
              height: 40px;  
              border-radius: 50%;
            }
            p{
                font-size: 14px;
                font-weight: 600;

                &.given-name{
                    font-weight: 500;
                }
            }
            display: flex;
            position: relative;
            align-items: center;
            gap:10px;
            z-index:1;
            cursor: pointer;

            .dropdown-menu{
               
                padding:10px;
                border-radius: 7px;
                box-shadow: 0px 10px 15px 3px rgba(0,0,0,0.1);
                border: 1px solid whitesmoke;
                position: absolute;
                background-color: white;
                top:0px;
                z-index: 10;
                opacity: 0;

                transition-duration: 1s;

                font-size: 14px;
                a{
                    text-decoration: none;
                }
            }

            &:hover{
                .dropdown-menu{
                    top:50px;
                    opacity: 1;
                }
            }
            
        }


    }

    .warning{
        color:red;
    }



}